<template>
    <b-sidebar
        id="sidebar-invoice-add-payment"
        ref="add-payment"
        :visible.sync="isSidebarVisible"
        sidebar-class="sidebar-lg"
        bg-variant="white"
        shadow
        backdrop
        no-header
        right
    >
        <template #default="{ hide }">
            <!-- Header -->
            <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                <h5 class="mb-0">
                    {{i18nT(`Record Payment`)}}
                </h5>

                <feather-icon
                    class="ml-1 cursor-pointer"
                    icon="XIcon"
                    size="16"
                    @click="closeSidebar"
                />

            </div>

            <!-- Body -->
            <b-form
                class="p-2"
                @submit.prevent
            >
                <validation-observer
                    ref="paymentForm">

                    <!-- Invoice Due Amount -->
                    <div class="text-center">
                        <b-form-group
                            :label="i18nT(`Total remaining invoice amount after payment`)"
                            label-for="invoice-balance"
                            class="pr-1"
                        >
                            <span class="font-large-1">{{ {amount: previewDewAmount, currency: currency, locale: locale} | currency}}</span>
                            <p v-if="previewDewAmount < 0" class="text-warning">
                                <feather-icon
                                    icon="AlertTriangleIcon"
                                    size="16"
                                />
                                {{i18nT(`The recorded payment amount is greater than the remaining invoice due amount!`)}}
                            </p>
                        </b-form-group>
                    </div>

                    <!-- Invoice Balance -->
                    <div class="d-flex">

                        <b-form-group
                            :label="i18nT(`Payment Amount`)"
                            label-for="payment-amount"
                            class="required"
                        >
                            <validation-provider
                                #default="{ errors }"
                                :name="` `"
                                rules="required"
                            >
                                <b-form-input
                                    id="payment-amount"
                                    v-model="addPaymentData.Amount"
                                    :placeholder="i18nT(`1000`)"
                                    :state="errors.length > 0 ? false : null"
                                    trim
                                    type="text"
                                />
                                <span class="text-danger">{{ errors[0] }}</span>
                            </validation-provider>
                        </b-form-group>

                        <b-form-group
                            class="pl-1"
                            :label="i18nT(`Currency`)"
                            label-for="invoice-balance"
                        >
                            <b-form-input
                                id="invoice-balance"
                                type="text"
                                :value="currency"
                                trim
                                disabled
                            />
                        </b-form-group>
                    </div>

                    <!-- Payment Date -->
                    <b-form-group
                        :label="i18nT(`Payment Date`)"
                        label-for="payment-date"
                        class="required"
                    >
                        <validation-provider
                            #default="{ errors }"
                            :name="i18nT(`Payment date`)"
                            rules="required"
                        >
                            <date-picker
                                reset-button
                                v-model="addPaymentData.PaymentDate"
                                :placeholder="i18nT(`Payment date`)"
                                :start-weekday="1"
                                :locale="locale"
                                :date-format-options="{
                                                year: 'numeric',
                                                month: 'short',
                                                day: '2-digit',
                                                weekday: 'short',
                                              }"
                            />
                            <span class="text-danger">{{ errors[0] }}</span>
                        </validation-provider>

                    </b-form-group>

                    <b-form-group
                        :label="i18nT(`Payment method`)"
                        label-for="payment-method"
                        class="required"
                    >
                        <validation-provider
                            #default="{ errors }"
                            :name="i18nT(`Payment date`)"
                            rules="required"
                        >
                            <b-form-select
                                v-model="addPaymentData.PaymentType"
                                :options="PaymentTypes"
                                label="text"
                                :placeholder="i18nT(`Select payment method`)"
                                input-id="payment-method"
                                :clearable="false"
                                :state="errors.length > 0 ? false : null"
                            />
                            <span class="text-danger">{{ errors[0] }}</span>
                        </validation-provider>
                    </b-form-group>

                    <!-- Internal Payment Note -->
                    <b-form-group
                        :label="i18nT(`Payment Note/Description`)"
                        label-for="internal-payment-note"
                    >
                        <b-form-textarea
                            id="internal-payment-note"
                            v-model="addPaymentData.Description"
                            :placeholder="i18nT(`Reason for payment`)"
                            rows="5"
                            trim
                        />
                    </b-form-group>

                </validation-observer>

                <!-- Form Actions -->
                <div class="d-flex mt-2">
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        class="mr-2"
                        type="submit"
                        @click="saveRecord()"

                    >
                        {{i18nT(`Record Payment`)}}
                    </b-button>
                    <b-button
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        variant="outline-secondary"
                        @click="closeSidebar"
                    >
                        {{i18nT(`Cancel`)}}
                    </b-button>
                </div>
            </b-form>
        </template>
    </b-sidebar>
</template>

<script>
import {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton, BFormSelect,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {dictToSelectArray} from "@core/utils/utils";
import DatePicker from "@core/components/DatePicker.vue";
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from 'vee-validate/dist/rules'
import moment from 'moment';

export default {
    components: {
        BFormSelect,
        BSidebar,
        BForm,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BButton,
        DatePicker,
        ValidationProvider,
        ValidationObserver,
    },
    directives: {
        Ripple,

    },
    data() {
        return {
            isSidebarVisible: true,
            required,
            locale: this.$i18n.locale,
            PaymentTypes: [],
            paymentChannels: [],
            addPaymentData: {
                id:null,
                Amount: '',
                PaymentDate: moment().format('YYYY-MM-DD'),
                PaymentType: null,
                Description: '',
            },

        }
    },
    props: {
        curLocale: {
            type: String,
            default: 'en',
        },
        invoiceId: {
            type: Number,
            default: null,
        },
        currency: {
            type: String,
            default: 'EUR',
        },
        paymentRecord: {
            type: Object,
            default: () => {
                return {

                }
            },
        },
        dueAmount: {
            type: Number,
            default: 0,
        },
        visible: {
            type: Boolean,
            default: false,
        },
        invoiceStatus: {
            type: String,
            default: ''
        }
    },
    computed: {
        previewDewAmount() {
            if(this.paymentRecord.Id){
                return this.dueAmount + this.paymentRecord.Amount - this.addPaymentData.Amount;
            } else {
                return this.dueAmount - this.addPaymentData.Amount;
            }
        },
        hasError(){
            return this.addPaymentData.Amount > this.dueAmount;
        }
    },
    mounted() {

        this.$http.get(`system/invoicePaymentTypes`).then((resp) => {
            this.PaymentTypes = dictToSelectArray(resp.data.data);
        });



    },
    methods: {
        closeSidebar(){
            this.$emit('close');
            // this.clearForm();
        },
        clearForm(){
            this.addPaymentData = {
                id:null,
                Amount: '',
                PaymentDate: moment().format('YYYY-MM-DD'),
                PaymentType: null,
                Description: '',
            }
            this.$refs.paymentForm.reset();
        },
        saveRecord(){
            let self = this;
            this.$refs.paymentForm.validate().then(success => {
                if (success) {
                    if(this.invoiceStatus === 'not_paid') {
                        this.$swal({
                        title: this.i18nT(`Disclaimer`),
                        text: this.i18nT(`You won't be able to delete an invoice after recording a payment!`),
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonText: this.i18nT(`Record!`),
                        customClass: {
                            confirmButton: 'btn btn-primary',
                            cancelButton: 'btn btn-outline-danger ml-1'
                        },
                        buttonsStyling: false
                    })
                    .then(result => {
                        if(result.value) {
                                self.postData()
                            }
                        })
                    }
                    else {
                        self.postData()
                    }
                    
                }
            });

        },
        postData() {
            let self = this

            let formData = new FormData();
            
            if(this.addPaymentData.Id){
                formData.append('id', this.addPaymentData.Id);
            }

            formData.append('InvoiceId', this.invoiceId);
            formData.append('Currency', this.currency);
            formData.append('PaymentChannel', 'manual');

            formData.append('Amount', this.addPaymentData.Amount);
            formData.append('PaymentDate', this.addPaymentData.PaymentDate);
            formData.append('PaymentType', this.addPaymentData.PaymentType);
            formData.append('Description', this.addPaymentData.Description);

            self.$http.post(`invoices/invoicePayment`, formData).then((resp) => {
                self.$emit('updated', resp.data.data);
                self.closeSidebar()
            });
        }

    },
    watch: {
        visible: {
            handler: function (val) {
                this.isSidebarVisible = (val === true);
            },
            immediate: true
        },
        curLocale: {
            handler: function (val) {
                this.locale = val;
            },
            immediate: true
        },
        paymentRecord: {
            handler: function (val) {
                let paymentMethodData = {text: this.PaymentTypes[val.PaymentMethod], value: val.PaymentMethod};
                this.addPaymentData = {...val};
                this.addPaymentData.PaymentMethod = paymentMethodData;
            },
            deep: true
        },
        dueAmount: {
            handler: function (val) {
                this.addPaymentData.Amount = val;
            },
            immediate: true
        },
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
