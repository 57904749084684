<template>
  <b-sidebar
    id="sidebar-send-invoice"
    sidebar-class="sidebar-lg"
    bg-variant="white"
    v-model="showSidebar"
    shadow
    backdrop
    no-header
    right
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{i18nT(`Send Invoice`)}}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- Body -->
      <b-form
        class="p-2"
        @submit.prevent
      >

        <!-- From -->
        <b-form-group
          :label="i18nT(`From`)"
          label-for="from"
        >
          <b-form-input
            id="from"
            v-model="sidebarForm.sender"
            trim
            type="email"
          />
        </b-form-group>

        <!-- To -->
        <b-form-group
          :label="i18nT(`To`)"
          label-for="to"
        >
          <b-form-input
            id="to"
            v-model="sidebarForm.receiver"
            trim
            type="email"
          />
        </b-form-group>

        <b-form-group
              :label="i18nT(`Message template`)"
              label-for="message-template"
          >
              <b-form-select
                  v-if="messageTemplates"
                  id="message-template"
                  v-model="message_template"
                  :options="messageTemplates"
                  trim></b-form-select>
          </b-form-group>

        <!-- Subject -->
        <b-form-group
          :label="i18nT(`Subject`)"
          label-for="subject"
        >
          <b-form-input
            id="subject"
            v-model="sidebarForm.subject"
            trim
          />
        </b-form-group>

         


        <!-- Message -->
        <b-form-group
          :label="i18nT(`Message`)"
          label-for="message"
        >
          <b-form-textarea
            id="message"
            v-model="sidebarForm.message"
            rows="12"
            trim
          />
        </b-form-group>


          <b-form-group
              :label="i18nT(`Link language template`)"
              label-for="message-template"
          >
              <b-form-select
                  v-if="cultures"
                  id="message-template"
                  v-model="selectedCulture"
                  :options="cultures"
                  trim></b-form-select>
          </b-form-group>

        <div class="w-100 py-1">
            <b-row>
                <b-col cols="12">
                    <span>{{i18nT(`You can use the following placeholders in your invoice message`)}}:</span><br/>
                    <p class="pt-1"><em>[invoice_number], [invoice_amount], [company_name], [invoice_duedate], [invoice_link]</em></p>
                    <span>
                        {{i18nT(`Set your default message in the settings for this software`)}} <b-link :to="`/ac/settings/invoice-messages`">{{i18nT(`by clicking here`)}}</b-link>
                    </span>
                </b-col>
            </b-row>
        </div>


        <!-- Form Actions -->
        <div class="d-flex mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="submit"
            @click="sendInvoice"
          >
              {{i18nT(`Send`)}}
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            @click="hide"
          >
              {{i18nT(`Cancel`)}}
          </b-button>
        </div>
      </b-form>
    </template>
  </b-sidebar>
</template>

<script>
import {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BFormSelect,
    BRow,
    BCol,
    BLink,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from "@/store";
import {useUtils as useI18Utils} from "@core/libs/i18n";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {dictToSelectArray} from "@core/utils/utils";
const {i18n} = useI18Utils();

export default {
  components: {
    BSidebar,
      BForm,
      BFormGroup,
      BFormInput,
      BFormTextarea,
      BButton,
      BFormSelect,
      BRow,
      BCol,
      BLink
  },
      directives: {
        Ripple,
      },
    data() {
      return {
          selectedCulture: '',
          cultures: [],
          messageTemplates: null,
          message_template: '',
          showSidebar: false,
          defaultMessage: i18n(`Dear Customer`)+`,

            `+i18n(`Thank you for doing business with us, the invoice for the provided services can be found below!`)+`,

            `+i18n(`Kind Regards`),
          formSubject: '',
      }

    },
    mounted() {
        this.getCultures();
    },
    computed: {
      invoiceData(){
          return store.getters['invoices/getInvoiceData'];
      },
      sidebarForm() {
          let invoiceData = store.getters['invoices/getInvoiceData'];
          let form = {
              invoice_id: '',
              sender: '',
              receiver: '',
              subject: this.formSubject,
              message: this.defaultMessage,
              message_template: '',
          }
          if(invoiceData && invoiceData.WorkspaceObj){
              form.invoice_id = invoiceData.Id;
              form.sender = invoiceData.WorkspaceObj.BusinessEmail;
              form.receiver = invoiceData.VendorObj.BusinessEmail;
              form.subject = this.formSubject ? this.formSubject : i18n(`Invoice #`)+ invoiceData.InvoiceNumber;
          }
          return form;
      },
  },
  methods: {
      getCultures() {
          this.$http.get(`system/cultures`).then(({data}) => {
              this.cultures = dictToSelectArray(data.data)


          })
      },
    sendInvoice() {
        const formData = new FormData();
        for (const prop in this.sidebarForm) {
            formData.append(prop, this.sidebarForm[prop])
        }
        this.$http.post('invoices/sendInvoice', formData).then(response => {
            this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                    title: this.successMessage(response),
                    icon: "InfoIcon",
                    variant: "success",
                },
            });
            this.$emit('sent');
            this.showSidebar = false;
        }).catch((err) => {
            this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                    title: this.errorMessage(err),
                    icon: "InfoIcon",
                    variant: "danger",
                },
            });
        });

    }
  },
    watch: {
        message_template: function (val) {
            if(val){
                //debugger;
                let message = this.messageTemplates.find((item) => item.value === val);
                this.defaultMessage = message.fullData.Message;
                this.formSubject = message.fullData.Label;
            }
        },
        invoiceData: {
            handler: function (val) {
                if(val){
                    this.$http.get('invoices/invoiceMessages').then(response => {
                        this.messageTemplates = response.data.data.map((item) => {
                            return {value: item.Id, text: item.Label, fullData: item}
                        });
                        this.message_template = this.messageTemplates[0].value;
                        this.defaultMessage = response.data.data[0].Message;
                        this.formSubject = response.data.data.Label;
                    });
                }
            },
            deep: true
        }
    }
}
</script>

<style>

</style>
