<template>
    <section class="invoice-preview-wrapper">

        <!-- Alert: No item found -->
        <b-alert
            variant="danger"
            :show="invoiceData === undefined"
        >
            <h4 class="alert-heading">
                {{ i18nT(`Error fetching invoice data`) }}
            </h4>
            <div class="alert-body">
                {{ i18nT(`No invoice found with this invoice id. Check`) }}
                <b-link
                    class="alert-link"
                    :to="{ name: 'invoices'}"
                >
                    {{ i18nT(`Invoice List`) }}
                </b-link>
                {{ i18nT(`for other invoices.`) }}
            </div>
        </b-alert>

        <b-row class="frame-row">
            <b-col
                cols="12"
                xl="9"
                md="8"
                id="frame-holder"
            >
                <b-row :class="isCreditNote ? 'mb-4' : ''">
                    <b-col cols="12">
                        <iframe id="print-frame"
                                v-if="invoiceData.IframePublicUrl"
                                :src="invoiceData.IframePublicUrl+'&lang='+locale" width="100%" height="1000px"
                                frameborder="0"></iframe>
                        <div id="frame-spinner"
                             class="position-absolute
                                    w-100
                                    d-flex justify-content-center
                                    align-items-center align-content-center">
                            <b-spinner label="Loading..."
                                       variant="primary"
                                       class="mr-2"
                                       color="primary"></b-spinner>
                        </div>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col cols="12" md="12" class="my-2">
                        <div class="
                                bg-white
                                text-center
                                d-flex
                                justify-content-center
                                align-items-center
                                py-2
                                box-shadow-1
                            ">
                            <invoice-payment-history
                                :payment-entries="invoiceData.InvoicePayments ? invoiceData.InvoicePayments : []"
                                :currency="invoiceData.Currency ? invoiceData.Currency : 'EUR'"
                                :due-amount="invoiceData.DueAmount ? invoiceData.DueAmount : 0"
                                :cur-locale="locale"
                                :adminMode="true"
                                @editRecord="setRecordToEdit"
                                @refresh="refetchInvoiceData"
                            />
                        </div>
                    </b-col>
                </b-row>
            </b-col>
            <!-- Right Col: Card -->
            <b-col
                cols="12"
                md="4"
                xl="3"
                class="invoice-actions"
            >
                <b-card>

                    <div v-if="getStatusTitle" class="status-holder pb-2 text-center">
                        <span class="status-title mr-05">
                            {{ i18nT(`Status`) }}:
                        </span>
                        <span
                            :class="statusBadgeClasses()">
                          {{ getStatusTitle }}
                      </span>
                        <div class="mt-2">
                            <div v-if="SentAt" class="font-small-3">
                                <strong>{{ i18nT(`Sent on`) }}: </strong><span>{{ SentAt | date }}</span>
                            </div>
                            <div v-if="SentTo" class="font-small-3">
                                <strong>{{ i18nT(`Sent to`) }}: </strong><span>{{ SentTo }}</span>
                            </div>
                        </div>
                    </div>

                    <!-- Button: Send Invoice -->
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        v-b-toggle.sidebar-send-invoice
                        variant="primary"
                        class="mb-75"
                        block
                    >
                        <font-awesome-icon icon="paper-plane" class="mr-1"/>
                        {{ i18nT(`Send Invoice`) }}
                    </b-button>

                    <!-- Button: Download -->
                    <b-button
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        variant="outline-secondary"
                        class="mb-75"
                        block
                        @click="openDownload"
                    >
                        <font-awesome-icon icon="download" class="mr-1"/>
                        {{ i18nT(`Download`) }}
                    </b-button>

                    <b-button
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        variant="outline-secondary"
                        class="mb-75"
                        block
                        @click="showPublicLink"
                    >

                        <font-awesome-icon icon="link" class="mr-1"/>
                        {{ i18nT(`Open public link`) }}
                    </b-button>


                    <!-- Button: Edit -->
                    <b-button
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        variant="outline-primary"
                        class="mb-75"
                        block
                        :to="{ name: editLink, params: { id: $route.params.id } }"
                    >
                        <font-awesome-icon icon="pen" class=""/>
                        {{ isCreditNote ? i18nT(`Edit credit note`) : i18nT(`Edit`) }}
                    </b-button>

                    <!-- Button: Add Payment -->
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="outline-secondary"
                        class="mb-75"
                        block
                        v-if="invoiceData.RecordType != 3"
                        :disabled="invoiceData.DueAmount <= 0 || isCreditNote"
                        @click="openAddPaynmentModal"
                    >
                        <font-awesome-icon icon="plus" class="mr-1"/>
                        {{ i18nT(`Record Payment`) }}
                    </b-button>
                </b-card>
            </b-col>

        </b-row>


        <invoice-sidebar-send-invoice @sent="refetchInvoiceData"/>
        <invoice-sidebar-add-payment
            :visible="isAddPaymentVisible"
            :invoice-id="$route.params.id ? parseInt($route.params.id) : null"
            :due-amount="invoiceData.DueAmount ? parseFloat(invoiceData.DueAmount) : 0"
            :payment-record="selectedPayment"
            :currency="invoiceData.Currency ? invoiceData.Currency : 'EUR'"
            :cur-locale="locale"
            @updated="refetchInvoiceData"
            @close="closeAddPayment"

        />
    </section>
</template>

<script>
//import router from '@/router'
import {
    BRow,
    BCol,
    BCard,
    BButton,
    BAlert,
    BLink,
    VBToggle, BSpinner,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import store from '@/store'
import InvoiceSidebarSendInvoice from '../InvoiceSidebarSendInvoice.vue'
import InvoiceSidebarAddPayment from '../InvoiceSidebarAddPayment.vue'
import InvoicePaymentHistory from "@/views/apps/invoice/InvoicePaymentHistory.vue";

export default {
    directives: {
        Ripple,
        'b-toggle': VBToggle,
    },
    components: {
        BSpinner,
        InvoicePaymentHistory,
        BRow,
        BCol,
        BCard,
        BButton,
        BAlert,
        BLink,
        InvoiceSidebarAddPayment,
        InvoiceSidebarSendInvoice,
    },
    data() {
        return {
            isAddPaymentVisible: false,
            selectedPayment: {},
            invoiceDescription: [],
            locale: this.$i18n.locale,
        }
    },

    methods: {
        statusBadgeClasses() {
            let base_classes = 'badge badge-pill ';
            switch (this.invoiceData.PaidStatus) {
                case 'partial':
                    return base_classes + 'badge-light-warning';
                case 'paid':
                    return base_classes + 'badge-success';
                case 'overdue':
                    return base_classes + 'badge-dark-danger';
                case 'overpaid':
                    return base_classes + 'badge-info';
                case 'void':
                    return base_classes + 'badge-light-dark';
                case 'not_paid':
                    return base_classes + 'badge-danger';
                default:
                    return base_classes + 'badge-info';
            }
        },
        openAddPaynmentModal() {
            if(this.isAddPaymentVisible) {
                this.isAddPaymentVisible = false;
            }
            setTimeout(() => {
                this.isAddPaymentVisible = true;
            }, 0);
        },
        closeAddPayment() {
            this.isAddPaymentVisible = false;
        },
        refetchInvoiceData() {
            store.dispatch('invoices/fetchInvoiceDetails', this.$route.params.id);
            this.isAddPaymentVisible = false;
        },
        setRecordToEdit(record) {
            this.selectedPayment = record;
            if(this.isAddPaymentVisible) {
                this.isAddPaymentVisible = false;
            }
            setTimeout(() => {
                this.isAddPaymentVisible = true;
            }, 0);
        },
        printInvoice() {
            window.print()
        },
        openDownload() {
            window.open(this.invoiceData.InvoiceDownloadUrl + `&lang=${this.locale}`, '_blank');
        },
        showPublicLink() {
            window.open('/invoice/' + this.invoiceData.Id + '/h/' + this.invoiceData.Hash + '?lang=' + this.locale, '_blank');
        }
    },
    mounted() {
        let enumsReady = store.getters['invoices/getEnumsState'];

        if (enumsReady) {
            store.dispatch('invoices/populateEnums');
        }
        store.dispatch('invoices/fetchInvoiceDetails', this.$route.params.id);
    },
    computed: {
        isCreditNote(){
            return this.invoiceData && this.invoiceData.RecordType == 3;
        },
        SentAt() {
            if (this.invoiceData && this.invoiceData.SentAt) {
                return this.invoiceData.SentAt;
            } else {
                return null;
            }
        },
        SentTo() {
            if (this.invoiceData && this.invoiceData.SentTo) {
                return this.invoiceData.SentTo;
            } else {
                return null;
            }
        },
        getStatusTitle() {
            if (this.invoiceData && this.invoiceData.PaidStatus) {
                let status = this.invoiceData.PaidStatus;
                return this.i18nT(status.charAt(0).toUpperCase() + status.slice(1)).replace('_', ' ');
            } else {
                return null;
            }
        },
        editLink() {
            return this.isCreditNote ? 'edit-credit-note' : 'edit-invoice';
        },
        invoiceEnums() {
            return store.getters['invoices/getInvoiceEnums'];
        },
        curInvoiceId() {
            return this.$route.params.id;
        },
        invoiceData() {
            return store.getters['invoices/getInvoiceData'];
        }
    },
    watch: {
        curInvoiceId(val, oldVal) {
            if (val && val !== oldVal)
                store.dispatch('invoices/fetchInvoiceDetails', val);
        }
    }

}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";

#frame-holder {
    //background: url("/static/invoice-preview-bg.png") no-repeat center center;
    //background-size: 30%;
    min-height: 680px;
}


#print-frame {
    width: 100%;
    height: calc(100vh - 380px);
    max-height: 1000px;
    min-height: 680px;
    border: none;
    z-index: 5;
    position: relative;
}

#frame-spinner {
    z-index: 2;
    top: 50%;
}
</style>

<style lang="scss">
@media print {

    // Global Styles
    body {
        background-color: transparent !important;
    }
    nav.header-navbar {
        display: none;
    }
    .main-menu {
        display: none;
    }
    .header-navbar-shadow {
        display: none !important;
    }
    .content.app-content {
        margin-left: 0;
        padding-top: 2rem !important;
    }
    footer.footer {
        display: none;
    }
    .card {
        background-color: transparent;
        box-shadow: none;
    }
    .customizer-toggle {
        display: none !important;
    }

    // Action Right Col
    .invoice-actions,
    .b-sidebar-outer,
    .footer,
    .crisp-client,
    .Vue-Toastification__container,
    .btn-scroll-to-top,
    .vertical-layout .card.suggestion-box,
    .vertical-layout .breadcrumb-wrapper,
    .content-overlay,
    .content-wrapper row:not(.frame-row) {
        display: none !important;
    }

    #frame-holder {
        width: 100vw !important;
        min-width: 820px;
        display: flex;
        justify-content: center;
        align-items: center;
        vertical-align: top;
    }

    iframe#print-frame {
        width: 100% !important;
        min-width: 800px;
    }

    // Invoice Specific Styles
    .invoice-preview-wrapper {
        //.row.invoice-preview {
        //  .col-md-8 {
        //    max-width: 100%;
        //    flex-grow: 1;
        //  }
        //
        //  .invoice-preview-card {
        //    .card-body:nth-of-type(2) {
        //      .row {
        //          > .col-12 {
        //          max-width: 50% !important;
        //        }
        //
        //        .col-12:nth-child(2) {
        //          display: flex;
        //          align-items: flex-start;
        //          justify-content: flex-end;
        //          margin-top: 0 !important;
        //        }
        //      }
        //    }
        //  }
        //}


    }
}
</style>
